import React, { useState } from "react";

const Checkmark = ({ query, size, strokeWidth, color }) => {
    return (
        <div
            className="position-absolute top-checkmark"
        >
            <svg
                className="checkmark"
                style={{
                    ...(size && { width: size }),
                    ...(size && { height: size }),
                    ...(strokeWidth && { strokeWidth: strokeWidth }),
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
            >
                <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                />
                <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
            </svg>
            <style jsx>{`
                .top-checkmark {
                    top: 2.6px;
                }

                .checkmark__circle {
                    stroke-dasharray: 166;
                    stroke-dashoffset: 166;
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                    stroke: ${query.btnBg && query.btnBg != "ffffff" ? `#${query.btnBg}` : color};
                    fill: none;
                    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1)
                        forwards;
                }

                .checkmark {
                    width: 46px;
                    height: 46px;
                    border-radius: 50%;
                    display: block;
                    stroke-width: 2;
                    stroke: #fff;
                    stroke-miterlimit: 10;
                    margin: 10% auto;
                    box-shadow: inset 0px 0px 0px
                        ${query.btnBg ? `#${query.btnBg}` : color};
                    animation: fill 0.4s ease-in-out 0.4s forwards,
                        scale 0.3s ease-in-out 0.9s both;
                }

                .checkmark__check {
                    transform-origin: 50% 50%;
                    stroke-dasharray: 48;
                    stroke-dashoffset: 48;
                    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s
                        forwards;
                }

                @keyframes stroke {
                    100% {
                        stroke-dashoffset: 0;
                    }
                }
                @keyframes scale {
                    0%,
                    100% {
                        transform: none;
                    }
                    50% {
                        transform: scale3d(1.1, 1.1, 1);
                    }
                }
                @keyframes fill {
                    100% {
                        box-shadow: inset 0px 0px 0px 30px
                            ${query.btnBg ? `#${query.btnBg}` : color};
                    }
                }

                @media (max-width: 768px) {
                    .checkmark {
                        width: 32px;
                        height: 32px;
                    }
                }
            `}</style>
        </div>
    );
};

export default Checkmark;
